import React, { Component } from 'react';
import homepage_image from '../homepage_image.png';


const homepage_image_style = {
  width: '95%'
}

export class thesproutreport extends Component {
  render() {
    return (
      <div>
       <br></br>
       <b>I made this</b>
       <br></br>
       <br></br>
       <a href="https://thesproutreport.com/home" target="_blank">The Sprout Report</a>
       <br></br>
       <br></br>
       <br></br>
       <h2 className="title is-3">User Home Example</h2>
        <img style={homepage_image_style} src={homepage_image} alt="homepage_image" />
      </div>
      )
  }
}

export default thesproutreport;

import React, { Component } from 'react';

export class music extends Component {
  render() {
    return (
      <div>
       <br></br>
       <b>Here is my music!</b>
       <br></br>
       <br></br>
       <a href="https://www.soundcloud.com/ryan-burke-260474319">my Soundcloud</a>
       <br></br>
       <br></br>
       <a href="https://originscanner.bandcamp.com/album/summer">my Bandcamp</a>

       <br></br>
       <br></br>
       <a href="https://www.instagram.com/ryanburke.life/">my Insta</a>
       <br></br>
       <br></br>
       <a href="https://www.youtube.com/channel/UCd3drepoDEPrtPRgFIOMM7w/videos">my Youtube</a>
       <br></br>
      </div>
      )
  }
}

export default music;

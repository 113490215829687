import React, { useState } from 'react';
import { twelveDataApiKey } from '../secrets';
import StockDataChart from './StockDataChart'; // import the new component


const StockData = () => {
  const [ticker, setTicker] = useState('');
  const [stockData, setStockData] = useState(null);

  const fetchData = async () => {
    if (!ticker) {
      alert('Please enter a ticker symbol');
      return;
    }

    try {
      const response = await fetch(`https://api.twelvedata.com/time_series?symbol=${ticker}&interval=1day&outputsize=7&apikey=${twelveDataApiKey}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      console.log(data)



      // Handling the case where the API returns an error message
      if (data.status && data.status !== 'ok') {
        throw new Error(data.message);
      }

      // Assuming the API returns an object with a 'values' array containing the stock data
      const latestData = data.values[0];
      setStockData({
        name: ticker, // Replace this with actual data if API provides it
        price: latestData.close, // Assuming 'close' price is what you need
        volume: latestData.volume
      });
    } catch (error) {
      console.error('Error fetching stock data:', error);
      alert('Failed to fetch stock data');
    }
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h1>Stock Data</h1>
      <input
        type="text"
        value={ticker}
        onChange={(e) => setTicker(e.target.value.toUpperCase())} // Convert ticker to uppercase
      />
      <button onClick={fetchData}>Fetch Stock Data</button>
      {stockData && (
        <div>
          <h2>{stockData.name}</h2>
          <p>Price: {stockData.price}</p>
          <p>Volume: {stockData.volume}</p>
          {/* Display other stock data properties as needed */}
          {/* <StockDataChart stockData={stockData} /> */}

        </div>
      )}
    </div>
  );
};

export default StockData;

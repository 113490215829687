import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, deleteDoc } from "firebase/firestore";
import { getFirestoreDB } from "../config/fbConfig.js"; // Adjust the import path as needed
import { secretKey } from '../secrets';

const secretCodeValue = secretKey; // Replace with your actual secret code

const TestComponent = () => {
  const [collections, setCollections] = useState([]);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);



  const fetchCollections = async () => {
    const firestore = getFirestoreDB();

    try {
      const collectionsRef = collection(firestore, 'stocks');
      const querySnapshot = await getDocs(collectionsRef);
      const fetchedCollections = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data()
      }));
      console.log(fetchedCollections)
      setCollections(fetchedCollections);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };


  useEffect(() => {
    fetchCollections();
  }, []);


  const handleSecretCodeInput = (e) => {
    setSecretCode(e.target.value);
    if (e.target.value === secretCodeValue) {
      setIsAuthorized(true);
    }
  };

  const handleAddCollection = async () => {
    if (newCollectionName.trim() === '') {
      return;
    }

    const newStock = {
      ticker: newCollectionName.trim()
    }

    const firestore = getFirestoreDB();
    const collectionsRef = collection(firestore, 'stocks');
    const newDoc = doc(collectionsRef);

    try {
      await setDoc(newDoc, newStock);
      setNewCollectionName('');
      fetchCollections();
    } catch (error) {
      console.error('Error adding a new collection:', error);
    }
  };

  const handleDeleteCollection = async (collectionId) => {
    const firestore = getFirestoreDB();
    const collectionsRef = collection(firestore, 'stocks');
    const collectionDocRef = doc(collectionsRef, collectionId); // Create a reference to the specific collection document.

    try {
      await deleteDoc(collectionDocRef);
      fetchCollections();
    } catch (error) {
      console.error('Error deleting the collection:', error);
    }
  };
  return (
    <div>
      <br />
      <br />
      <input
        type="text"
        placeholder="Enter Secret Code"
        value={secretCode}
        onChange={handleSecretCodeInput}
      />
      {isAuthorized && (
      <div>

      <h1>Firestore Collections</h1>
      <table>
        <thead>
          <tr>
            <th>Collection Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {collections.map((collection) => (
            <tr key={collection.id}>
              <td>{collection.data.ticker}</td>
              <td>
                <button onClick={() => handleDeleteCollection(collection.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <input
        type="text"
        value={newCollectionName}
        onChange={(e) => setNewCollectionName(e.target.value)}
      />
      <button onClick={handleAddCollection}>Add Collection</button>
    </div>
      )}

    </div>
  );
};

export default TestComponent;

import React, { Component } from 'react';

export class smallgroupchat extends Component {
  render() {
    return (
      <div>
       <br></br>
       <b>I also made this</b>
       <br></br>
       <br></br>
       <a href="https://smallgroupchat.com/" target="_blank">Small Group Chat</a>
       <br></br>
       <br></br>
       <br></br>
       <iframe width="560" height="315" src="https://www.youtube.com/embed/l6apNtNBb5k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      )
  }
}

export default smallgroupchat;

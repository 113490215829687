import { initializeApp } from "firebase/app";
import { firebaseSecrets } from '../secrets';
import { getFirestore } from "firebase/firestore";


const app = initializeApp(firebaseSecrets)

let firestore = false;



export const getFirestoreDB = () => {
  if(!firestore)
    firestore = getFirestore(app)
  return firestore
}

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { home } from './pages/home';
import { music } from './pages/music';
import { sandboxsampler } from './pages/sandboxsampler';
import { smallgroupchat } from './pages/smallgroupchat';
import { thesproutreport } from './pages/thesproutreport';
import { contact } from './pages/contact';
import { needthis } from './pages/needthis';
import  StockData  from './pages/stockdata';
import  TestComponent  from './pages/test';
export class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/home" component={home} />
          <Route exact path="/music" component={music} />
          <Route exact path="/thesproutreport" component={thesproutreport} />
          <Route exact path="/sandboxsampler" component={sandboxsampler} />
          <Route exact path="/smallgroupchat" component={smallgroupchat} />
          <Route exact path="/contact" component={contact} />
          <Route exact path="/needthis" component={needthis} />
          <Route exact path="/stockdata" component={StockData} />
          <Route exact path="/test" component={TestComponent} />


          {/* Displays our Login component as a fallback */}
          {/* <Route component={LoginSignUp} /> */}
        </Switch>
      </Router>
    );
  }
}

export default Routes;

import React, { Component } from 'react';

const box = {
  justifyContent: 'center',
  display: 'grid',
  gridTemplateColumns: "0.5fr 1fr",
  rowGap: '15px',
  paddingLeft: '15%',
  paddingRight: '15%',
  textAlign: 'left'
}
const contact = {
  textAlign: 'left',
  paddingLeft: '15%',
}


export class needthis extends Component {
  render() {
    return (
    <div>
      <br></br>
      <br></br>
      <br></br>
        <div style={box}>
          <div><b>My skills</b></div>
          <div><b></b></div>
          {/* ----------- */}
          <div><b>Area</b></div>
          <div><b>Expertise</b></div>
          {/* ----------- */}
          <div>PHP/HTML</div>
          <div>Fullstack Web Applications</div>
          {/* ----------- */}
          <div>JS/HTML</div>
          <div>Fullstack React/Redux Web Applications</div>
          {/* ----------- */}
          <div>SQL</div>
          <div>Database Design/Data Analysis</div>
          {/* ----------- */}
          <div>Debuging</div>
          <div>Codebase fixes and documentation</div>
          {/* ----------- */}
          <div>Dynamic PDFs</div>
          <div>Reports, Emails, Invoices, Templates</div>
          {/* ----------- */}
          <div>Power BI</div>
          <div>Report Building</div>
        </div>
        <br></br>
        <br></br>
        <div style={contact}>
          <a href="https://www.linkedin.com/in/ryandburke/">my linkedin</a>
          <br></br>
          <br></br>
          <b>thingstoryan@gmail.com</b>
        </div>
    </div>
    )
  }
}

export default needthis;

import React, { Component } from 'react';

export class contact extends Component {
  render() {
    return <div>
      <br></br>
      <b>thingstoryan@gmail.com</b>
      </div>;
  }
}

export default contact;

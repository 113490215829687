const firebaseSecrets = {
  apiKey: "AIzaSyBqoWGUH0BkG_4Sc3a43V2Pblab9MuN5Ko",
  authDomain: "portfolio-8ce4b.firebaseapp.com",
  databaseURL: "https://portfolio-8ce4b.firebaseio.com",
  projectId: "portfolio-8ce4b",
  storageBucket: "portfolio-8ce4b.appspot.com",
  messagingSenderId: "267871782308",
  appId: "1:267871782308:web:11fe24fc00ab45381eed31"
};
const twelveDataApiKey = "8bf0dbad03574dd79e06de3370acade7";

const secretKey = 'beep';

module.exports = {
  firebaseSecrets,
  twelveDataApiKey,
  secretKey,

};

import React, { Component } from 'react';

import './Navbar.css';

export class Navbar extends Component {
  render() {
    return <div>

      <ul>
        <li><a href="home">Home</a></li>
        <li><a href="music">Music</a></li>
        <li><a href="contact">Contact</a></li>
        <li><a href="needthis">Need This?</a></li>
        <li><a href="thesproutreport">The Sprout Report</a></li>
        <li><a href="sandboxsampler">Sandbox Sampler</a></li>
        <li><a href="smallgroupchat">Small Group Chat</a></li>
        {/* <li><a href="stockdata">Stock Data</a></li> */}
        {/* <li><a href="test">Test</a></li> */}
      </ul>
      </div>;
  }
}

export default Navbar;

import React, { Component } from 'react';

export class home extends Component {
  render() {
    return (
    <div>
      <br></br>
      <b>Hi and welcome to my website.</b>
      <br></br>
      <br></br>
      <b>take a look around!</b>

    </div>
    )
  }
}

export default home;

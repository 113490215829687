import React, { Component } from 'react';

export class sandboxsampler extends Component {
  render() {
    return (
      <div>
       <br></br>
       <b>I also made this</b>
       <br></br>
       <br></br>
       <a href="https://www.sandboxsampler.com/info" target="_blank">Sandbox Sampler</a>
       <br></br>
       <br></br>
       <br></br>
       <iframe width="560" height="315" src="https://www.youtube.com/embed/AmtDkz9kGDg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      )
  }
}

export default sandboxsampler;
